import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CardNews from "../components/cardNews"

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section className="section">
        <div className="columns is-centered is-marginless">
          <div className="column is-8 has-text-centered">
            <div className="notification is-danger is-size-3">
              <strong>Oups!</strong>
            </div>
            <article className="message is-info">
              <div className="message-body">
                <p className="is-size-5">
                  <strong>
                    La page que tu recherches n'est plus disponible.
                  </strong>
                </p>{" "}
                <br></br>
                <p>
                  <Link to={"/"}>
                     Visite ici la page d'accueil et découvre les actualités
                    de HauteCulture.
                  </Link>
                </p>
              </div>
            </article>
            <div css={rescale}>
              <CardNews news={data.allStrapiPosts.edges[0].node} />
            </div>
            <div style={{ marginTop: `5px` }} className="columns">
              {data.allStrapiPosts.edges.slice(1, 4).map((suggestion, i) => {
                return (
                  <div css={rescale} key={i} className="column is-4">
                    <CardNews news={suggestion.node} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    allStrapiPosts(limit: 4, sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          bait
          title
          content
          createdAt
          createdAtReadable: createdAt(
            formatString: "D MMMM YYYY à HH:mm"
            locale: "fr_FR"
          )
          type {
            name
          }
          image {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`
const rescale = css`
  :hover {
    transform: scale(1.02);
    transition-property: all;
    transition-property: transform;
    transition-duration: 0.3s;
  }
`
